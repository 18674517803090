<template>
  <div class="DAV-workshop__heading" :class="setClass">
    <button
      class="DAV-workshop__heading--prev"
      :class="setClass2"
      @click="goPrev()"
      v-show="color !== 'green'"
    >
      이전
    </button>
    <slot name="title"></slot>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleEditor = createNamespacedHelpers("ModuleEditor");

export default {
  props: {
    color: {
      type: String
    },
    panel: {
      type: String,
      default: "ComponentList"
    }
  },
  computed: {
    // class binding
    setClass() {
      return {
        "DAV-workshop__heading--green": this.color === "green"
      };
    },
    setClass2() {
      return {
        "DAV-workshop__heading--prev--option-mode":
          this.$route.path === "/option" &&
          this.getPopup.page === "ComponentImage"
      };
    },
    ...ModuleEditor.mapGetters(["getPopup", "getPanel"])
  },
  methods: {
    // 이전 페이지로 이동
    goPrev() {
      if (this.$route.path === "/option") {
        this.$emit("goPrev");
      } else {
        this.setPanel(this.panel);
      }
    },
    ...ModuleEditor.mapMutations(["setPanel"])
  }
};
</script>

<style lang="scss" scoped>
.DAV-workshop__heading {
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
  height: 56px;
  padding: 0 16px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

  .DAV-workshop__heading--prev {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-image: url($path + "bul_arr_left_gray.svg");
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -9999px;
    &--option-mode {
      display: block !important;
    }
  }
  h3 {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    position: relative;
  }
}
.DAV-workshop__heading--green {
  background: #54c7a2;
  box-shadow: none;

  .DAV-workshop__heading--prev {
    background-image: url($path + "bul_arr_left.svg");
  }
  h3 {
    color: #ffffff;
  }
}
</style>
