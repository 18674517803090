var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__heading",
    class: _vm.setClass
  }, [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.color !== 'green',
      expression: "color !== 'green'"
    }],
    staticClass: "DAV-workshop__heading--prev",
    class: _vm.setClass2,
    on: {
      "click": function click($event) {
        return _vm.goPrev();
      }
    }
  }, [_vm._v(" 이전 ")]), _vm._t("title")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }